
;(function ( $, window, document, undefined ) {

    var pluginName = "azNavigation",
        // the name of using in .data()
        dataPlugin = "plugin_" + pluginName,
        // default options
        defaults = {
        };

    var privateMethod = function () {
        console.log("private method");
    };

    // The actual plugin constructor
    var Plugin = function ( element ) {
        /*
         * Plugin instantiation
         */
        this.options = $.extend( {}, defaults );
    };

    Plugin.prototype = {

        init: function ( options ) {

            this.container = this.element.find('.level2_container');
            var _self = this;
            // var container = _self.element.find('.level2_container');

            console.log('init azNavigation');

            // extend options ( http://api.jquery.com/jQuery.extend/ )
            $.extend( this.options, options );

            this.element.find('.aznavtoggle a:not(.skipinvisible)').on('focus', function () {
                //$('.breadcrumbnavigation').find('.with-focus').removeClass('focus');
                $(this).parents('.aznavtoggle .level2_container').addClass('open');
                //$('.aznavigation .level2_container').toggleClass('open');
            }).on('blur', function () {
                $(this).parents('.aznavtoggle .level2_container').removeClass('open');
            });


            this.element.find('.aznavtoggle > a').on('click', function (event) {
                if (bpLevel <= bpLevelMedium) {
                    return;
                }

                event.preventDefault();

                if (!_self.container.hasClass('open')) {
                    var sensitivity = 50;
                    var offsetDist = 200;
                    var offset = $(this).offset();
                    var bottomElement = Math.floor(offset.top) + $(this).outerHeight() + sensitivity;
                    var scrollTop = $(window).scrollTop();
                    var windowHeight = $(window).outerHeight();
                    var bottomScreen = scrollTop + windowHeight;

                    var layerHeight = _self.container.outerHeight();

                    if (bottomScreen <= bottomElement) {
                        var body = $("html, body");
                        body.stop().animate({scrollTop: scrollTop + layerHeight}, '1000', 'swing', function () {
                        });
                    }

                }

                $(window).on('resize', function () {
                    _self.adjust();
                });

                _self.container.toggleClass('open');

            });

            this.element.find('a.close').on('click', function (event) {
                event.preventDefault();
                _self.container.toggleClass('open');
                $(this).parents('.columns').next().find('a').focus();
            });

            this.adjust();
        },

        adjust: function () {
            this.container.width($('.mainnavigation .level_1').width());
        },
    }

    /*
     * Plugin wrapper, preventing against multiple instantiations and
     * allowing any public function to be called via the jQuery plugin,
     * e.g. $(element).pluginName('functionName', arg1, arg2, ...)
     */
    $.fn[ pluginName ] = function ( arg ) {
        if (!this.length) {
            return;
        }

        var args, instance;

        // only allow the plugin to be instantiated once
        if (!( this.data( dataPlugin ) instanceof Plugin )) {

            // if no instance, create one
            this.data( dataPlugin, new Plugin( this ) );
        }

        instance = this.data( dataPlugin );

        instance.element = this;

        // Is the first parameter an object (arg), or was omitted,
        // call Plugin.init( arg )
        if (typeof arg === 'undefined' || typeof arg === 'object') {

            if ( typeof instance['init'] === 'function' ) {
                instance.init( arg );
            }

            // checks that the requested public method exists
        } else if ( typeof arg === 'string' && typeof instance[arg] === 'function' ) {

            // copy arguments & remove function name
            args = Array.prototype.slice.call( arguments, 1 );

            // call the method
            return instance[arg].apply( instance, args );

        } else {

            $.error('Method ' + arg + ' does not exist on jQuery.' + pluginName);

        }
    };

}(jQuery, window, document));