/*
!function (root, factory) {
  if (typeof define === 'function' && define.amd) {
    define(['jquery'], factory);
    console.log('define');
  } else {
    factory(root.jQuery);
  }

}(this, function ($) {
*/

global.jQuery = require("jquery");

(function ($) {

    'use strict';

    // Default options
    var defaults = {};


    // Constructor, initialise everything you need here
    var DzTeaserPager = function (element, options) {

        this.element = element;
        this.options = options;

        this.numPages = 0;

        this.isBusy = false;
        this.newPage = 0;
        this.actPage = 0;

        this.init();

    };


    // DzTest methods and shared properties
    DzTeaserPager.prototype = {

        constructor: DzTeaserPager,

        /** @private */
        init: function () {

            var _self = this;

            _self.numPages = _self.element.find('> div').length - 1;  // the first teaser is static

            if (_self.numPages > 1) {
                _self.reconstruct();
                _self.addEventListeners();
            }

        },


        reconstruct: function () {

            var _self = this;

            _self.element.find('> div:eq(0)').addClass('dz-teaser-pager__static');
            _self.element.find('> div:gt(0)').wrapAll('<div class="dz-teaser-pager__inner"></div>');

            _self.container = _self.element.find('.dz-teaser-pager__inner');
            _self.container.find('> div').addClass('dz-teaser-pager__page');
            _self.container.find('> div p > a').each(function () {
                var href = $(this).attr('href');
                var target = '';
                if ($(this).attr('target') !== undefined) {
                    target = 'target="' + $(this).attr('target') + '"';
                }

                $(this).parents('div.dz-teaser-pager__page').find('h3').wrapInner('<a href="' + href + '" ' + target + '></a>');
            });
            _self.container.prepend('<div class="dz-teaser-pager__nav"><a href="javascript:;" class="dz-teaser-pager__prev"><i class="icon-arrow_left_circle"></i><span class="invisible">zum vorherigen Thema</span></a><a href="javascript:;" class="dz-teaser-pager__next"><i class="icon-arrow_right_circle"></i><span class="invisible">zum nächsten Thema</span></a></div>');

            _self.adjustContainerNow();

        },


        addEventListeners: function () {
            var _self = this;

            _self.prevButton = _self.container.find('.dz-teaser-pager__prev');
            _self.nextButton = _self.container.find('.dz-teaser-pager__next');

            _self.prevButton.on('click', function () {
                _self.showPrev();
            });

            _self.nextButton.on('click', function () {
                _self.showNext();
            });

            $(window).on('resize', function () {
                _self.adjustContainerNow();
            });
        },


        // ------------- Event Listeners

        showPrev: function () {
            var _self = this;
            if (_self.actPage === 0) {
                _self.showPage(_self.numPages - 1);
            } else {
                _self.showPage(_self.actPage - 1);
            }
        },

        showNext: function () {
            var _self = this;
            if (_self.actPage == (_self.numPages - 1)) {
                _self.showPage(0);
            } else {
                _self.showPage(_self.actPage + 1);
            }
        },


        // -------------- Page functions

        showPage: function (page) {
            var _self = this;

            if (_self.isBusy) {
                return;
            }
            if (page < 0) {
                page = 0;
            }
            //alert(page + ' ' + _self.numPages);
            if (page >= _self.numPages) {
                page = _self.numPages - 1;
            }
            if (page == _self.actPage) {
                //return;
            }  // nothing to do

            //if ((page >= numPages) || (page < 0) || (page == actPage)) { return; }  // nothing to do

            _self.isBusy = true;
            _self.newPage = page;

            _self.changePage();

        },


        changePage: function () {
            var _self = this;

            console.log(_self.actPage);
            console.log(_self.newPage);

            _self.container.find('> div.dz-teaser-pager__page').eq(_self.actPage).fadeOut(500, function () {
                _self.manageEvent('change-completed');
            });

            _self.container.find('> div.dz-teaser-pager__page').eq(_self.newPage).fadeIn(500);

            _self.adjustContainer();


        },


        adjustContainer: function () {
            var _self = this;
            console.log(_self.container.find('> div.dz-teaser-pager__page').eq(_self.actPage).height());
            _self.container.animate({height: _self.container.find('> div.dz-teaser-pager__page').eq(_self.newPage).height()}, 500);

        },

        adjustContainerNow: function () {
            var _self = this;
            var actElement = _self.container.find('> div.dz-teaser-pager__page').eq(_self.actPage);

            if (actElement.find('.image_container').css('display') == 'block') {
                _self.container.height(actElement.height());
                _self.container.find('.text__inside').height(_self.container.height());
            } else {
                _self.container.height(actElement.find('.text_container').height() + 40);
                _self.container.find('.text__inside').height(_self.container.height());
            }

        },

        manageEvent: function (eventType) {
            var _self = this;

            switch (eventType) {

                case 'change-completed':
                    _self.actPage = _self.newPage;
                    //this.resetButtons();
                    _self.isBusy = false;
                    break;

                default:
                    alert('unknown event');
                    break;

            }
        }

    };


    // Create the jQuery DzTest
    $.fn.dzTeaserPager = function (options) {

        options = $.extend(true, {}, defaults, options);

        return this.each(function () {
            var $this = $(this);
            // Create a new instance for each element in the matched jQuery set
            // Also save the instance so it can be accessed later to use methods/properties etc
            // e.g.
            //    var instance = $('.element').data('plugin');
            //    instance.someMethod();

            $this.data('dzTeaserPager', new DzTeaserPager($this, options));

            //$this.data('testplugin').someMethod();

        });

    };

    // Expose defaults and Constructor (allowing overriding of prototype methods for example)
    //$.fn.dzTeaserPager.defaults = defaults;
    //$.fn.dzTeaserPager.DzTeaserPager = DzTeaserPager;


//});

}(jQuery));