;(function ($) {

    var hash = window.location.hash;

    if (hash.indexOf('#contact-') === 0) {
        var hashTarget = hash.replace('contact-', 'target-');
        $(hashTarget).addClass('hashed');

        // TODO: find a solution without timeout
        setTimeout(function () {
            window.scrollTo(0, 0);
            $([document.documentElement, document.body]).animate({
                scrollTop: $(hashTarget).offset().top - 100
            }, 0);
        }, 200);
    }

}(jQuery));
