
;(function ( $, window, document, undefined ) {

    var pluginName = "langNavigation",
        // the name of using in .data()
        dataPlugin = "plugin_" + pluginName,
        // default options
        defaults = {
            'selector': '.langnav'
        };

    var privateMethod = function () {
    };

    // The actual plugin constructor
    var Plugin = function ( element ) {
        /*
         * Plugin instantiation
         */
        this.options = $.extend( {}, defaults );
    };

    Plugin.prototype = {

        init: function ( options ) {

            this.container = this.element.find('.level2_container');
            var _self = this;

            console.log('init langNavigation');

            $.extend( this.options, options );

            this._addEventListeners();
        },


        _addEventListeners: function() {
            var _self = this;

            _self.element.find('a').on('mousedown', function (event) {
                if (event.which == 3) {
                    event.preventDefault();
                }
            });

            _self.element.find(' > a').on('click', function (event) {
                event.preventDefault();
                _self.container.toggleClass('open');
            });

            _self.container.find('a').on('focus', function () {
                _self.container.addClass('open');
            }).on('blur', function () {
                _self.container.removeClass('open');
            });
        }
    }

    /*
     * Plugin wrapper, preventing against multiple instantiations and
     * allowing any public function to be called via the jQuery plugin,
     * e.g. $(element).pluginName('functionName', arg1, arg2, ...)
     */
    $.fn[ pluginName ] = function ( arg ) {
        if (!this.length) {
            return;
        }

        var args, instance;

        // only allow the plugin to be instantiated once
        if (!( this.data( dataPlugin ) instanceof Plugin )) {

            // if no instance, create one
            this.data( dataPlugin, new Plugin( this ) );
        }

        instance = this.data( dataPlugin );

        instance.element = this;

        // Is the first parameter an object (arg), or was omitted,
        // call Plugin.init( arg )
        if (typeof arg === 'undefined' || typeof arg === 'object') {

            if ( typeof instance['init'] === 'function' ) {
                instance.init( arg );
            }

            // checks that the requested public method exists
        } else if ( typeof arg === 'string' && arg.indexOf('_') !== 0 && typeof instance[arg] === 'function' ) {

            // copy arguments & remove function name
            args = Array.prototype.slice.call( arguments, 1 );

            // call the method
            return instance[arg].apply( instance, args );

        } else {

            $.error('Method ' + arg + ' does not exist on jQuery.' + pluginName);

        }
    };

}(jQuery, window, document));