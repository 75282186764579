;(function ($) {

    var hash = window.location.hash;

    var activeAcc = false;
    var href = $(location).attr('href');

    if (href.indexOf("acc=") != -1) {
        var matches = href.match(/acc=([0-9]+)/);
        activeAcc = 1 * matches[1] - 1;
    }

    function accCreateCallback(event) {
        if (hash.indexOf('#acc-') === 0) {
            var hashedElement = $(hash);

            if (hashedElement) {
                var active = $(event.target).find('.ce_accordion').index(hashedElement);

                if (active >= 0) {
                    $(event.target).accordion("option", "active", active);
                    setTimeout(function () {
                        $('html, body').animate({
                            scrollTop: hashedElement.offset().top - 100
                        }, 0);
                    }, 500);

                }
            }
        }
    }

    $('.mod_article > .ce_accordion:not(.acc-closed):first-of-type, ' +
        '.event.layout_full > .ce_accordion:not(.acc-closed):first-of-type, ' +
        '.mod_newsreader > .layout_full > .ce_accordion:not(.acc-closed):first-of-type').parent().accordion({

        active: activeAcc,
        heightStyle: 'content',
        header: 'div.toggler',
        collapsible: false,
        create: function (event, ui) {
            ui.header.addClass('active');
            $('h2.toggler').attr('tabindex', 0);
            accCreateCallback(event);
        },
        activate: function (event, ui) {
            ui.newHeader.addClass('active');
            ui.oldHeader.removeClass('active');
            $('h2.toggler').attr('tabindex', 0);
        }
    });


    $('.mod_article > .ce_accordion.acc-closed:first-of-type, ' +
        '.event.layout_full > .ce_accordion.acc-closed:first-of-type, ' +
        '.mod_newsreader > .layout_full > .ce_accordion.acc-closed:first-of-type').parent().accordion({

        active: false,
        heightStyle: 'content',
        header: 'div.toggler',
        collapsible: true,
        create: function (event, ui) {
            ui.header.addClass('active');
            $('h2.toggler').attr('tabindex', 0);
            // $('html, body').animate({
            //     scrollTop: ui.header.offset().top - 100
            // }, 0);
            accCreateCallback(event);
        },
        activate: function (event, ui) {
            ui.newHeader.addClass('active');
            ui.oldHeader.removeClass('active');
            $('h2.toggler').attr('tabindex', 0);
        }
    });

}(jQuery));
