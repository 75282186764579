import('./timeline');

// $(document).ready(function () {
//
//     if ($('.ce_dztabs').length) {
//         log.info('load tabs');
//
//         import('./tabs').then(({ }) => {});
//     }
//
// });
