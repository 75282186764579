
;(function ( $, window, document, undefined ) {

    var pluginName = "mainNavigation",
        // the name of using in .data()
        dataPlugin = "plugin_" + pluginName,
        // default options
        defaults = {
        };

    var privateMethod = function () {
    };

    // The actual plugin constructor
    var Plugin = function ( element ) {
        /*
         * Plugin instantiation
         */
        this.options = $.extend( {}, defaults );
    };

    Plugin.prototype = {

        init: function ( options ) {

            this.container = this.element.find('.level2_container');
            var _self = this;

            console.log('init mainNavigation');

            $.extend( this.options, options );

            _self.equalize();

            this._addEventListeners();
        },

        equalize: function () {
            if (bpLevel <= bpLevelMedium) {
                return;
            }

            this.element.find('.level_2 .lileft').each(function () {

                var lileft = $(this);
                var liright = lileft.next();

                if (liright.hasClass('liright')) {
                    lileft.outerHeight('auto');
                    liright.outerHeight('auto');

                    var leftHeight = lileft.outerHeight();
                    var rightHeight = liright.outerHeight();

                    if (leftHeight != rightHeight) {
                        var maxHeight = Math.max(leftHeight, rightHeight);

                        lileft.outerHeight(maxHeight);
                        liright.outerHeight(maxHeight);
                    }

                }

            });
        },

        fix: function () {
            this.element.addClass('fixed');
            $('.mainnav_placeholder').addClass('fixed');
        },

        release: function () {
            this.element.removeClass('fixed');
            $('.mainnav_placeholder').removeClass('fixed');
        },

        _addEventListeners: function() {
            var _self = this;

            // disable right mouse click for main navigation

            // enable tab usage for main navigation

            this.element.find('a').on('mousedown', function (event) {
                if (event.which == 3) {
                    event.preventDefault();
                    //alert('right button pressed');
                }
            });

            this.element.find('ul.level_1 > li a').on('focus', function () {
                _self.element.find('.with-focus').removeClass('focus');
                $(this).parents('.with-focus').addClass('focus');
            });

            $('a').on('focus touchstart', function () {
                // close main navigation layer if tab focus is outside of the main navigation
                if ($(this).parents('.mainnavigation').length === 0) {
                    _self.element.find('.with-focus').removeClass('focus');
                }
            });

            this.element.find('ul.level_1 > li > a').on('touchstart', function (event) {
                // prevent default events if layer is closed
                if ($(this).parent().find('.level2_container').css('visibility') === 'hidden') {
                    event.preventDefault();
                }

                // close all open level2 layers
                _self.element.find('.level2_container').css('visibility', 'hidden');

                $(this).parent().find('.level2_container').css('visibility', 'visible');

            });

            $('.toggle-mobile').on('click', function () {
                $('.mobilenav_layer').toggleClass('active');
                $('.toggle-mobile').toggleClass('active');
            });

            $(window).on('resize', function () {
                _self.equalize();
            });

            $(window).on('scroll', function () {
                var scrollTop = $(window).scrollTop();
                var headerHeight = $('.topheader').height();

                if (scrollTop > headerHeight) {
                    _self.fix();
                } else {
                    _self.release();
                }
            });
        }
    }

    /*
     * Plugin wrapper, preventing against multiple instantiations and
     * allowing any public function to be called via the jQuery plugin,
     * e.g. $(element).pluginName('functionName', arg1, arg2, ...)
     */
    $.fn[ pluginName ] = function ( arg ) {
        if (!this.length) {
            return;
        }

        var args, instance;

        // only allow the plugin to be instantiated once
        if (!( this.data( dataPlugin ) instanceof Plugin )) {

            // if no instance, create one
            this.data( dataPlugin, new Plugin( this ) );
        }

        instance = this.data( dataPlugin );

        instance.element = this;

        // Is the first parameter an object (arg), or was omitted,
        // call Plugin.init( arg )
        if (typeof arg === 'undefined' || typeof arg === 'object') {

            if ( typeof instance['init'] === 'function' ) {
                instance.init( arg );
            }

            // checks that the requested public method exists
        } else if ( typeof arg === 'string' && arg.indexOf('_') !== 0 && typeof instance[arg] === 'function' ) {

            // copy arguments & remove function name
            args = Array.prototype.slice.call( arguments, 1 );

            // call the method
            return instance[arg].apply( instance, args );

        } else {

            $.error('Method ' + arg + ' does not exist on jQuery.' + pluginName);

        }
    };

}(jQuery, window, document));