/*
!function (root, factory) {
  if (typeof define === 'function' && define.amd) {
    define(['jquery'], factory);
    console.log('define');
  } else {
    factory(root.jQuery);
  }

}(this, function ($) {
*/

global.jQuery = require("jquery");

(function ($) {

    'use strict';

    // Default options
    var defaults = {};


    // Constructor, initialise everything you need here
    var DzContentPager = function (element, options) {

        this.element = element;
        this.options = options;

        this.numPages = 0;

        this.isBusy = false;
        this.newPage = 0;
        this.actPage = 0;

        this.autoplay = false;
        this.timerActive = false;

        this.init();

    };


    // DzTest methods and shared properties
    DzContentPager.prototype = {

        constructor: DzContentPager,

        /** @private */
        init: function () {

            var _self = this;

            _self.numPages = _self.element.find('> div').length;

            if (_self.numPages > 1) {
                if (_self.element.hasClass('shuffle')) {
                    //_self.shuffle();
                }

                if (_self.element.hasClass('autoplay')) {
                    _self.autoplay = true;
                }

                _self.reconstruct();
                _self.addEventListeners();
            }

        },


        shuffle: function () {

            var _self = this;

            var divs = _self.element.find('> div');

            while (divs.length) {
                _self.element.append(divs.splice(Math.floor(Math.random() * divs.length), 1)[0]);
            }

            _self.element.find('> div').addClass('hide-on-load');
            _self.element.find('> div').eq(0).removeClass('hide-on-load');
        },

        reconstruct: function () {

            var _self = this;

            _self.element.find('> div').wrapAll('<div class="dz-content-pager__inner"></div>');
            _self.element.prepend('<div class="dz-content-pager__nav"></div>');
            _self.element.find('.dz-content-pager__nav').append('<a href="javascript:;" class="dz-content-pager__prev"><i class="icon-arrow_left_circle"></i></a><a href="javascript:;" class="dz-content-pager__next"><i class="icon-arrow_right_circle"></i></a>');

            _self.container = _self.element.find('.dz-content-pager__inner');
            _self.container.find('> div').addClass('dz-content-pager__page');

            if (_self.autoplay) {
                _self.element.find('.dz-content-pager__nav').prepend('<a href="javascript:;" class="dz-content-pager__toggleplay" title="Dia show pausieren/starten"><i class="icon-play-button play"></i><i class="icon-pause pause"></i></a>');
            }

            _self.adjustContainerNow();

        },


        addEventListeners: function () {
            var _self = this;

            _self.prevButton = _self.element.find('.dz-content-pager__prev');
            _self.nextButton = _self.element.find('.dz-content-pager__next');
            _self.toggleplayButton = _self.element.find('.dz-content-pager__toggleplay');

            _self.prevButton.on('click', function () {
                // if (!_self.timerActive) {
                    _self.showPrev();
                // }
                _self.stop();
                _self.toggleplayButton.addClass('paused');
            });

            _self.nextButton.on('click', function () {
                // if (!_self.timerActive) {
                    _self.showNext();
                // }
                _self.stop();
                _self.toggleplayButton.addClass('paused');
            });

            _self.toggleplayButton.on('click', function () {
                if (_self.timerActive) {
                    _self.stop();
                    $(this).addClass('paused');
                } else {
                    _self.play();
                    $(this).removeClass('paused');
                }
            });

            if (_self.autoplay) {
                _self.play();
            }

            $(window).on('resize', function () {
                _self.adjustContainerNow();
            });
        },

        play: function () {
            var _self = this;

            _self.timer = setInterval(function () {
                _self.showNext();
            }, 10000);
            _self.timerActive = true;
        },

        stop: function () {
            var _self = this;

            clearInterval(_self.timer);
            _self.timerActive = false;
        },

        // ------------- Event Listeners

        showPrev: function () {
            var _self = this;
            if (_self.actPage === 0) {
                _self.showPage(_self.numPages - 1);
            } else {
                _self.showPage(_self.actPage - 1);
            }
        },

        showNext: function () {
            var _self = this;
            if (_self.actPage == (_self.numPages - 1)) {
                _self.showPage(0);
            } else {
                _self.showPage(_self.actPage + 1);
            }
        },

        // -------------- Page functions

        showPage: function (page) {
            var _self = this;

            if (_self.isBusy) {
                return;
            }
            if (page < 0) {
                page = 0;
            }
            //alert(page + ' ' + numPages);
            if (page >= _self.numPages) {
                page = _self.numPages - 1;
            }
            if (page == _self.actPage) {
                //return;
            }  // nothing to do

            //if ((page >= numPages) || (page < 0) || (page == actPage)) { return; }  // nothing to do

            _self.isBusy = true;
            _self.newPage = page;

            _self.changePage();

        },


        changePage: function () {
            var _self = this;

            _self.container.find('> div').eq(_self.actPage).fadeOut(500, function () {
                _self.manageEvent('change-completed');
            });

            _self.container.find('> div').eq(_self.newPage).fadeIn(500);

            _self.adjustContainer();


        },


        adjustContainer: function () {
            var _self = this;
            _self.container.animate({height: _self.container.find('> div').eq(_self.newPage).height()}, 500);

        },

        adjustContainerNow: function () {
            var _self = this;
            _self.container.height(_self.container.find('> div').eq(_self.actPage).height());

        },

        manageEvent: function (eventType) {
            var _self = this;

            switch (eventType) {

                case 'change-completed':
                    _self.actPage = _self.newPage;
                    //this.resetButtons();
                    _self.isBusy = false;
                    break;

                default:
                    alert('unknown event');
                    break;

            }
        }

    };


    // Create the jQuery DzTest
    $.fn.dzContentPager = function (options) {

        options = $.extend(true, {}, defaults, options);

        return this.each(function () {
            var $this = $(this);
            // Create a new instance for each element in the matched jQuery set
            // Also save the instance so it can be accessed later to use methods/properties etc
            // e.g.
            //    var instance = $('.element').data('plugin');
            //    instance.someMethod();

            $this.data('dzContentPager', new DzContentPager($this, options));

            //$this.data('testplugin').someMethod();

        });

    };

    // Expose defaults and Constructor (allowing overriding of prototype methods for example)
    //$.fn.dzContentPager.defaults = defaults;
    //$.fn.dzContentPager.DzContentPager = DzContentPager;


//});

}(jQuery));