var viewportWidth;
var breakpoint;
var bpLevel;

var bpInter = 480;
var bpLevelInter = 1;
var bpMedium = 720;
var bpLevelMedium = 2;
var bpLarge = 980;
var bpLevelLarge = 3;
var bpXlarge = 1100;
var bpLevelXlarge = 4;
var bpXXlarge = 1200;
var bpLevelXXlarge = 5;

global.bpLevelMedium = bpLevelMedium;


function updateBreakpoint() {
    viewportWidth = window.innerWidth;
    //console.log(viewportWidth);

    // var breakpoint = '';

    if (viewportWidth < bpInter) {
        breakpoint = 'small';
        bpLevel = 0;
    } else if (viewportWidth < bpMedium) {
        breakpoint = 'inter';
        bpLevel = 1;
    } else if (viewportWidth < bpLarge) {
        breakpoint = 'medium';
        bpLevel = 2;
    } else if (viewportWidth < bpXlarge) {
        breakpoint = 'large';
        bpLevel = 3;
    } else if (viewportWidth < bpXXlarge) {
        breakpoint = 'xlarge';
        bpLevel = 4;
    } else {
        breakpoint = 'xxlarge';
        bpLevel = 5;
    }
    $('body').removeClass('small inter medium large xlarge xxlarge').addClass(breakpoint);

    global.bpLevel = bpLevel;
    global.viewportWidth = viewportWidth;
    //console.log(breakpoint);
}

function equalizeAll() {
    $('.equalize').equalize();

    if (bpLevel == bpLevelMedium) {
        $('.no-eq-medium').css('height', 'auto');
    }

    if (bpLevel < bpLevelMedium) {
        $('.equalize > div:not(.noeq)').css('height', 'auto');
    }
}

function initEdgeLegacy() {
    var browser = (function (agent) {
        switch (true) {
            case agent.indexOf("edge") > -1: return "edge";
            // case agent.indexOf("edg") > -1: return "chromium based edge (dev or canary)";
            // case agent.indexOf("opr") > -1 && !!window.opr: return "opera";
            case agent.indexOf("chrome") > -1 && !!window.chrome: return "chrome";
            // case agent.indexOf("trident") > -1: return "ie";
            case agent.indexOf("firefox") > -1: return "firefox";
            // case agent.indexOf("safari") > -1: return "safari";
            default: return "other";
        }
    })(window.navigator.userAgent.toLowerCase());

    if (browser === 'edge') {
        $('.edge-only').show();
    }

    // console.log(window.navigator.userAgent.toLowerCase() + "<br>" + browser);
}

export {updateBreakpoint, equalizeAll, initEdgeLegacy}