var ES6Promise = require("es6-promise");
ES6Promise.polyfill();

// Foundation 6.7 / not working with IE11 / webpack / babel
// import $ from 'jquery';
// import {Foundation} from 'foundation-sites/js/foundation.core';
// Foundation.addToJquery($);

// require('foundation-sites/js/foundation.core');

import {updateBreakpoint, equalizeAll, initEdgeLegacy} from './js/modules/helperFunctions';

global.$ = global.jQuery = $;
window.$ = window.jQuery = $;

(function ($) {
    'use strict';

    // var viewportWidth;
    // var breakpoint;
    // var bpLevel;
    //
    // var bpInter = 480;
    // var bpLevelInter = 1;
    // var bpMedium = 720;
    // var bpLevelMedium = 2;
    // var bpLarge = 980;
    // var bpLevelLarge = 3;
    // var bpXlarge = 1100;
    // var bpLevelXlarge = 4;
    // var bpXXlarge = 1200;
    // var bpLevelXXlarge = 5;
    //
    // global.bpLevelMedium = bpLevelMedium;


    /* jQuery */
    // global.$ = global.jQuery = require('jquery');
    require('jquery-ui/ui/widgets/accordion');
    require('jquery-ui/ui/widgets/tabs');
    require('jquery-ui/ui/widgets/autocomplete');

    require('slick-carousel');
    require('./js/vendor/equalize'); // TODO
    require('./js/vendor/jquery.colorbox-min'); // TODO needed?

    /* Foundation */
    require('foundation-sites/js/foundation.core'); // TODO
    require('foundation-sites/js/foundation.util.mediaQuery');
    require('foundation-sites/js/foundation.util.keyboard');
    // require('foundation-sites/js/foundation.util.timerAndImageLoader');

    /* optional Foundation elements */
    require('foundation-sites/js/foundation.tabs');

    // require('foundation-sites'); // TODO

    /* initialize Foundation */
    $(document).foundation();

    /* bundles */
    /* most bundles include their own javascript */
    require('web_bundles/contaotimeline/init');

    /* project */
    require('./js/plugins/jquery.dzContentPager');
    require('./js/plugins/jquery.dzTeaserPager')
    require('./js/plugins/mainNavigation');
    require('./js/plugins/azNavigation');
    require('./js/plugins/breadcrumbNavigation');
    require('./js/plugins/langNavigation');

    require('./js/modules/contactHash');
    require('./js/modules/sticky');
    require('./js/modules/accordion');

    // require('web_bundles/contaodzcontrastswitcher/jquery.dzContrastSwitcher');
    // require('web_bundles/contaodzasapf/asa');
    // require('web_bundles/contaodzcalendarext/eventSearchForm');
    // require('web_bundles/contaodzcalendarext/eventsHomepage');
    // require('web_bundles/contaodzcompass/jquery.dzEngagementCompass');
    // require('web_bundles/contaodzgallery/gallery');
    // require('web_bundles/contaodzheadergallery/headerteaser');
    // require('web_bundles/contaodzheadergallery/jquery.dzHeaderGallery');
    // require('web_bundles/contaodzsearchext/autocomplete');
    // require('web_bundles/contaodzsearchext/searchext');
    // require('web_bundles/contaodzskewfinder/jquery.dzSkewFinder');
    // require('web_bundles/contaodzsocialmedia/jquery.dzSocialMedia');
    // require('web_bundles/contaodztabs/dzTabs');
    // require('web_bundles/contaodzvideogallery/jquery.dzVideoGallery');
    require('web_bundles/contaodzsocialshare/shariff.complete');


    // INIT

    $(window).on('load', function () {
        updateBreakpoint();

        // $('#contrast_toggle').dzContrastSwitcher();
        // $('#eg-navigator').dzEngagementCompass({imagePath: '/files/1_Bilder/Bilder_EG/Kompass/'});
        // // $('.ce_dzheadergallery').dzHeaderGallery();
        // $('#skew-navigator').dzSkewFinder({});
        // $('.ce-dz-socialmedia-box').dzSocialMedia();
        // $(document).generateTabs();
        // $('.ce_dzvideogallery').dzVideoGallery();

        $('.mainnavigation').mainNavigation();
        $('.aznavigation').azNavigation();
        $('.breadcrumbnavigation').breadcrumbNavigation();
        $('.langnav').langNavigation();

        if ($('.dz-teaser-pager').length) {
            $('.dz-teaser-pager').dzTeaserPager();
        }

        if ($('.dz-content-pager').length) {
            $('.dz-content-pager').dzContentPager();
        }

        $('a[data-lightbox]').map(function () { // TODO
            $(this).colorbox({
                // Put custom options here
                loop: false,
                rel: $(this).attr('data-lightbox'),
                maxWidth: '95%',
                maxHeight: '95%'
            });
        });

        equalizeAll();
        initEdgeLegacy();
        initSurveyTeaser();
        initBacklink();

        if (window.location.hash) {
            var hash = window.location.hash;

            if ((hash === '#tm') && $(hash).length) {
                $('html, body').animate({
                    scrollTop: $(hash).offset().top - 100
                }, 900, 'swing');
            }
        }

    });


    // ON RESIZE

    $(window).on('resize', function () {
        updateBreakpoint();
        equalizeAll();
    });

    // document.querySelectorAll("a").forEach(function(current) {
    //     if(current.hash !== '#tm') return;
    //     // if(current.origin + current.pathname != self.location.href) return;
    //     (function(anchor) {
    //         if(anchor) {
    //             current.addEventListener("click", function(e) {
    //                 anchor.scrollIntoView({behavior: "smooth"});
    //                 e.preventDefault();
    //             }, false);
    //         }
    //     })(document.querySelector(current.hash));
    // });

}(jQuery));


function initSurveyTeaser() {
    $('.js-teaser-survey').on('click', function (event) {
        event.preventDefault();

        $(this).parents('.teaser-survey').toggleClass('is-collapsed');
    });
}

function initBacklink() {
    $('.js-back').on('click', function (event) {
        event.preventDefault();
        window.history.back();
    });
}
