/*
    sticky navigation see mainNavigation.js
 */

;(function ($) {

    var headerHeight = $('.topheader').height();

    $(window).on('scroll', function () {
        var scrollTop = $(window).scrollTop();
        if (scrollTop > headerHeight) {
            $('.header > .mod_search').addClass('fixed');
        } else {
            $('.header > .mod_search').removeClass('fixed');
        }
    })

}(jQuery));